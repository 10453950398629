import React, {Component} from "react";
import { Card } from 'primereact/card';
import { Wheel } from 'react-custom-roulette'
import { Button } from "primereact/button";

function getRandomArbitrary(min:number, max:number) {
    return Math.random() * (max - min) + min;
}

export default class Radje extends Component <{},{mustSpin:boolean, prizeNumber:number}>{
    constructor(props:any){
        super(props)
        this.state = {
            mustSpin: false,
            prizeNumber: 0
        }
    }

    handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * 47)
        console.log(newPrizeNumber)
        this.setState({
            prizeNumber: newPrizeNumber,
            mustSpin: true
        })
      };

    render(): React.ReactNode {
        const data = [
            { option: '1', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Trek een kan (3 bier)' },
            { option: '2', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Trek een blik' },
            { option: '3', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Speel steen-papier-bak met persoon naar keuze' },
            { option: '4', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Doneer 47 cent aan de huyschrekening' },
            { option: '5', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Deel een walrus uit' },
            { option: '6', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'10 push-ups' },
            { option: '7', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Wees gezond, drink een glas water' },
            { option: '8', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Adtje 2 hoog' },
            { option: '9', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Doe een shotje van de restplank' },
            { option: '10', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Deel een spies uit' },
            { option: '11', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Gefeliciteerd!! Trek een spies' },
            { option: '12', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Doe een vleermuisadt' },
            { option: '13', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Draai nog 2 keer' },
            { option: '14', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Doe een brokrol' },
            { option: '15', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Ga 4,7 minuten in de hoek staan' },
            { option: '16', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Trek een bierkanon' },
            { option: '17', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Minderjarig goorlap, trek een spies' },
            { option: '18', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Draai rood, anders spies' },
            { option: '19', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Doe een bodyshot van persoon rechts van je' },
            { option: '20', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Deel een spies uit' },
            { option: '21', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Trek een Gerard' },
            { option: '22', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Gefeliciteerd!! Trek een spies' },
            { option: '23', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Speel een potje honderden' },
            { option: '24', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Deel een DSK uit' },
            { option: '25', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Draai groen, anders spies' },
            { option: '26', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Draai nog 2 keer' },
            { option: '27', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Deel een shotje uit van de restplank' },
            { option: '28', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'HJ trekt een spies' },
            { option: '29', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Trek een walrus' },
            { option: '30', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Deel een spies uit' },
            { option: '31', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Zing het eerste couplet van het volkslied, anders spies' },
            { option: '32', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Deel een bierkanon uit' },
            { option: '33', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Gefeliciteerd!! Trek een spies' },
            { option: '34', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Eet 2 eieren' },
            { option: '35', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Deel een blik uit' },
            { option: '36', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Trek een DSK' },
            { option: '37', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Doe een shotje van de restplank' },
            { option: '38', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Draai blauw, anders spies' },
            { option: '39', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Spiegel iemand voor 47 minuten' },
            { option: '40', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Deel een spies uit' },
            { option: '41', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Doe 3 pull-ups' },
            { option: '42', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Trek aantal slokken van oudste die je geregeld hebt' },
            { option: '43', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Kies iemand die jou voor 47 minuten spiegelt' },
            { option: '44', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Gefeliciteerd!! Trek een spies' },
            { option: '45', style: { backgroundColor: 'blue', textColor: 'white' }, opdracht:'Deel een vluirmuisadt uit' },
            { option: '46', style: { backgroundColor: 'red', textColor: 'white' }, opdracht:'Ren een rondje om de gracht, binnen 4,7 minuten' },
            { option: '47', style: { backgroundColor: 'green', textColor: 'white' }, opdracht:'“VO” Iedereen trekt een spies' }
        ]

        return(
            <Card style={{width:'100%', margin: 'auto', maxWidth:'800px'}} title='Radje'>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Wheel
                        innerRadius={30}
                        mustStartSpinning={this.state.mustSpin}
                        prizeNumber={this.state.prizeNumber}
                        data={data}
                        outerBorderWidth={2}
                        radiusLineWidth={2}
                        perpendicularText={true}
                        textDistance={90}
                        startingOptionIndex={46}
                        disableInitialAnimation={true}
                        onStopSpinning={() => {
                            alert(data[this.state.prizeNumber].opdracht)
                            this.setState({
                                mustSpin: false
                            })
                        }}
                        />
                    <Button style={{marginTop:50}} onClick={() => {this.handleSpinClick()}} label="Draaien"></Button>
                </div>
            </Card>
        )
    }
}