import React, {Component} from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect'
import { FloatLabel } from 'primereact/floatlabel';

export default class KookboekZoekBalk extends Component<{gerechten:any, updategerecht:Function, alleIngredienten:any},{zoekopdracht:string, selectedIngredienten:any}>{
    constructor(props:any){
        super(props)
        this.state = {
            zoekopdracht: '',
            selectedIngredienten: []
        }
    }

    filterGerecht(gerecht:any){
        let passZoekopdracht:boolean
        let passIngredienten = false

        if( gerecht.kok.toUpperCase().includes(this.state.zoekopdracht.toUpperCase()) || gerecht.gerecht.toUpperCase().includes(this.state.zoekopdracht.toUpperCase()) ){
            passZoekopdracht = true
        }else{
            passZoekopdracht = false
        }

        for(let ingredient of this.state.selectedIngredienten){
            if(JSON.parse(gerecht.ingredienten).some((e:any) => e.ingredient === ingredient.ingredient)){
                passIngredienten = true
            }
        }

        if(this.state.selectedIngredienten.length === 0){
            passIngredienten = true
        }

        if(passZoekopdracht && passIngredienten){
            return true
        }else{
            return false
        }
    }

    render(): React.ReactNode {
        if(this.state.zoekopdracht !== '' || this.state.selectedIngredienten.length !== 0){
            return(
                <div>
                    <IconField style={{width:'100%'}} iconPosition="left">
                        <InputIcon className="pi pi-search"> </InputIcon>
                        <InputText onChange={(e) => this.setState({zoekopdracht: e.target.value})} style={{width:'100%'}} placeholder="Zoeken" />
                    </IconField>
                    <br />
                    <FloatLabel>
                        <label htmlFor="boek">Ingredienten: </label>
                        <MultiSelect filter={true} scrollHeight="60vh" display="chip" style={{width:'100%'}} panelStyle={{}} value={this.state.selectedIngredienten} onChange={(e) => this.setState({selectedIngredienten: e.value})} options={this.props.alleIngredienten} optionLabel="ingredient" maxSelectedLabels={100}/>
                    </FloatLabel>
                    <br />
                    <div>
                        {this.props.gerechten.map((gerecht:any, index:number) => {
                            if(this.filterGerecht(gerecht)){
                                return(
                                    <div onClick={() => this.props.updategerecht(gerecht)} className="hover-grow" style={{textAlign:'left', background:'white',flexGrow:1, border:'1px solid #ced4da', padding:'4px 10px', borderRadius:'4px', marginBottom:'5px'}} key={index}>
                                        {gerecht.gerecht} - {gerecht.type_gerecht}
                                        <br />
                                        {gerecht.kok} - {gerecht.boek}
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <br />
                </div>
            )
        }else{
            return(
                <div>
                    <IconField style={{width:'100%'}} iconPosition="left">
                        <InputIcon className="pi pi-search"> </InputIcon>
                        <InputText onChange={(e) => this.setState({zoekopdracht: e.target.value})} style={{width:'100%'}} placeholder="Zoeken" />
                    </IconField>
                    <br />
                    <FloatLabel>
                        <label htmlFor="boek">Ingredienten: </label>
                        <MultiSelect filter={true} scrollHeight="60vh" display="chip" style={{width:'100%'}} panelStyle={{}} value={this.state.selectedIngredienten} onChange={(e) => this.setState({selectedIngredienten: e.value})} options={this.props.alleIngredienten} optionLabel="ingredient" maxSelectedLabels={100}/>
                    </FloatLabel>
                    <br />
                </div>
            )
        }
    }
}