import React, {Component}from 'react';
import { PrimeIcons, PrimeReactProvider } from 'primereact/api';

import 'primeicons/primeicons.css';
import "primereact/resources/themes/viva-light/theme.css";
import './App.css';

import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { url } from 'inspector';

import Spel from './Spel';
import Steenpapierbak from './Steenpapierbak';
import Kookboek from './Kookboek';
import Dobbels from './Dobbels';
import Tijdlijn from './Tijdlijn';
import Radje from './Radje';

import lustrumlogo from './images/7de lustrumlogo.jpg'
import kaart from './images/kaart.png'

class App extends Component<{},{currentPage:string}>{
  constructor(props:any){
    super(props)
    this.state = {
      currentPage: 'home'
    }
  }

  matchpage(){
    if(this.searchToObject().page === 'home' && this.state.currentPage !== 'home'){
      this.setState({currentPage: 'home'})
    }else if(this.searchToObject().page === 'mokkenspel' && this.state.currentPage !== 'mokkenspel'){
      this.setState({currentPage: 'mokkenspel'})
    }else if(this.searchToObject().page === 'steenpapierbak' && this.state.currentPage !== 'steenpapierbak'){
      this.setState({currentPage: 'steenpapierbak'})
    }else if(this.searchToObject().page === 'kookboek' && this.state.currentPage !== 'kookboek'){
      this.setState({currentPage: 'kookboek'})
    }else if(this.searchToObject().page === 'tijdlijn' && this.state.currentPage !== 'tijdlijn'){
      this.setState({currentPage: 'tijdlijn'})
    }else if(this.searchToObject().page === 'dobbels' && this.state.currentPage !== 'dobbels'){
      this.setState({currentPage: 'dobbels'})
    }else if(this.searchToObject().page === 'radje' && this.state.currentPage !== 'radje'){
      this.setState({currentPage: 'radje'})
    }
  }

  searchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj:any = {},
      pair,
      i;
  
    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;
  
      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
  
    return obj;
  }

  setPage(page:string){
    window.history.pushState(null,'', '/?page=' + page)
    this.setState({currentPage: page})
  }

  render(){

    let menuitems = [
      {
        label: 'Home',
        icon: PrimeIcons.HOME,
        url: '/',
        command: () => this.setPage('home')
      },
      {
        label: 'Eetlijst',
        icon: PrimeIcons.WALLET,
        url: '/eetlijst'
      },
      {
        label: 'Pilslijst',
        icon: PrimeIcons.CAR,
        url: '/pilslijst'
      },
      {
        label: 'Huyschkookboek',
        icon: PrimeIcons.BOOK,
        command: () => this.setPage('kookboek')
      },
      {
        label: 'Mokkenspel',
        icon: PrimeIcons.CAMERA,
        command: () => this.setPage('mokkenspel')
      },
      {
        label: 'Tijdlijn',
        icon: PrimeIcons.SLIDERS_H,
        command: () => this.setPage('tijdlijn')
      },
      {
        label: 'Steen papier bak',
        icon: PrimeIcons.SHIELD,
        command: () => this.setPage('steenpapierbak')
      },
      {
        label: 'Dobbels',
        icon: PrimeIcons.TH_LARGE,
        command: () => this.setPage('dobbels')
      },
      {
        label: 'Radje',
        icon: PrimeIcons.UNDO,
        command: () => this.setPage('radje')
      },
      {
        label: 'Is het 5v5?',
        icon: PrimeIcons.CLOCK,
        command: () => alert('Het is vast wel ergens 5v5, tijd voor pils!')
      }
    ]

    this.matchpage()

    let pagecontent = <div></div>

    if(this.state.currentPage === 'home'){
      pagecontent = <img className='kaart' src={kaart} alt="" />
    }else if(this.state.currentPage === 'mokkenspel'){
      pagecontent = <Spel></Spel>
    }else if(this.state.currentPage === 'steenpapierbak'){
      pagecontent = <Steenpapierbak></Steenpapierbak>
    }else if(this.state.currentPage === 'kookboek'){
      pagecontent = <Kookboek></Kookboek>
    }else if(this.state.currentPage === 'dobbels'){
      pagecontent = <Dobbels></Dobbels>
    }else if(this.state.currentPage === 'tijdlijn'){
      pagecontent = <Tijdlijn></Tijdlijn>
    }else if(this.state.currentPage === 'radje'){
      pagecontent = <Radje></Radje>
    }

    return (
      <PrimeReactProvider>
        <Menubar style={{margin: 20}} model={menuitems} />
  
        <div style={{display: 'none'}}>
          <Button onClick={() => {
            alert('Het is vast wel ergens 5v5! Tijd voor pils!')
          }} label='Is het al 5v5?'/>
        </div>
          
        {pagecontent}
        
        <div className='footer'>
          <img src={lustrumlogo} alt="" />
        </div>
      </PrimeReactProvider>
    );
  }
}

export default App;
