import React, {Component} from "react";
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import Dice from "react-dice-roll";

export default class Dobbels extends Component<{},{aantalDobbels:number}>{
    constructor(props:any){
        super(props)
        this.state = {
            aantalDobbels: 2
        }
    }

    render(){
        return(
            <Card style={{width:'100%', margin: 'auto', maxWidth:'800px'}} title='Dobbels'>
                <Dropdown onChange={(e) => {
                        this.setState({aantalDobbels: parseInt(e.value)})
                    }} optionLabel="name" optionValue="name" value={this.state.aantalDobbels.toString()} style={{width:'50%', backgroundColor:'#00000000', textAlign:'left'}} id="boek" options={[{name: '1'}, {name: '2'}, {name: '3'}, {name: '4'}, {name: '5'}, {name: '6'}, {name: '7'}, {name: '8'}, {name: '9'}, {name: '10'}]}></Dropdown>
                <br />
                <br />
                <br />
                <div style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>
                    {Array.from(Array(this.state.aantalDobbels).keys()).map((index:any) => {
                        return(
                            <div style={{width: 100, margin:'auto', marginBottom:30}}>
                                <Dice rollingTime={470} size={100} placement="bottom-right"></Dice>
                            </div>
                        )
                    })}
                </div>
            </Card>
        )
    }
}