import React, {Component} from "react";
import { Card } from 'primereact/card';
import { Slider } from 'primereact/slider';
import { ToggleButton } from 'primereact/togglebutton';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

export default class Tijdlijn extends Component<{},{height:any, omgekeerd:any}>{
    constructor(props:any){
        super(props)
        this.state = {
            height: 8000,
            omgekeerd: false
        }
    }

    isEven(n:number) {
        return n % 2 == 0;
    }

    render(): React.ReactNode {
        const totaalmaanden = 424
        const tijden = [
            [
                {naam: 'Peter LeClerq', maanden:68}, 
                {naam: 'Gijsbrecht "Vondel" Speyart v. Woerden', maanden:113}, 
                {naam: 'Remco "Leen" v. Dongen', maanden:91}, 
                {naam: 'Dominika Kubieneic', maanden:3}, 
                {naam: 'Huub "Goofy" Rijper', maanden:49}, 
                {naam: 'OH Dirkedurk', maanden:3}, 
                {naam: 'Huub "Goofy" Rijper', maanden:12}, 
                {naam: 'Jelte "Olaf" de Wit', maanden:62}, 
                {naam: 'Derk "Mitchell" Hermans', maanden:23}
            ],[
                {naam: 'KIM', maanden:12}, 
                {naam: 'Paul "Pik" Everts', maanden:71}, 
                {naam: 'Michiel "Bubbel" Bekker', maanden:49}, 
                {naam: 'Maarten', maanden:6}, 
                {naam: 'Tjerk', maanden:5}, 
                {naam: 'Michiel "Bubbel" Bekker', maanden:8}, 
                {naam: 'Jopie', maanden:2}, 
                {naam: 'Jody "Jopie" Verpoort', maanden:69}, 
                {naam: 'Egemen "Zorba" Dogan', maanden:6}, 
                {naam: 'Jody "Jopie" Verpoort', maanden:15}, 
                {naam: 'Christiaan "Woody" Waters', maanden:105}, 
                {naam: 'Alex "Julien" Hamel', maanden:36}, 
                {naam: 'OH Koen "Malone" Thijssen', maanden:6}, 
                {naam: 'Alex "Julien" Hamel', maanden:23}, 
                {naam: 'OH Jappie', maanden:7}, 
                {naam: 'Faas "HJ Saaf" van Raaij ', maanden:4}
            ],[
                {naam: 'Onno "Nono" Rutten', maanden:60}, 
                {naam: 'Gleuf', maanden:5}, 
                {naam: 'Onno "Nono" Rutten', maanden:12}, 
                {naam: 'Wijnand "Mickey" v. Till', maanden:22}, 
                {naam: 'Edwin "Freddy H" Pol', maanden:59}, 
                {naam: 'Edwin "Frederik "Winston" Birnie', maanden:98}, 
                {naam: 'Rogier "Jeltsin" Vrooman', maanden:78}, 
                {naam: 'Ivo "Steen" Schols', maanden:58}, 
                {naam: 'Victor "Jeff" Cevaal', maanden:32}
            ],[
                {naam: 'KIM', maanden:49}, 
                {naam: 'Jochem', maanden:15}, 
                {naam: 'Buis', maanden:1}, 
                {naam: 'Andrew "Gleuf" Glavimans', maanden:34}, 
                {naam: 'Joost "Tejoo K" Brands', maanden:20}, 
                {naam: 'Silvester "Olivier" Heijdeman', maanden:6}, 
                {naam: 'Joost "Tejoo K" Brands', maanden:45}, 
                {naam: 'Martijn "De Sjon" de Rooij', maanden:64}, 
                {naam: 'Thijs "Van Rossem" Bennebroek', maanden:54}, 
                {naam: 'Bob', maanden:6}, 
                {naam: 'Thijs "Van Rossem" Bennebroek', maanden:17}, 
                {naam: 'Léon "Mo" Collenteur', maanden:37}, 
                {naam: 'OH Nout Rijper', maanden:6}, 
                {naam: 'Léon "Mo" Collenteur', maanden:19}, 
                {naam: 'Martijn "Stifler" Legué', maanden:35}, 
                {naam: 'Emile "Willie" van Raaij', maanden:16}
            ],[
                {naam: 'KIM', maanden:36}, 
                {naam: 'David "Sanana" Knops', maanden:72}, 
                {naam: 'Steven "Sietse" Moerman', maanden:82}, 
                {naam: 'Dion', maanden:4}, 
                {naam: 'Steven "Sietse" Moerman', maanden:7}, 
                {naam: 'Razvan', maanden:3}, {naam: 'Bob Bol', maanden:2}, 
                {naam: 'Vincent "Sukke" v.d. Wielen', maanden:69}, 
                {naam: 'Yannic de Jong', maanden:4}, 
                {naam: 'Vincent "Sukke" v.d. Wielen', maanden:15}, 
                {naam: 'Jeroen "Bob" Koot', maanden:76}, 
                {naam: 'Pepe "Noah" Rubbens', maanden:25}, 
                {naam: 'Niels "Dwight" Maring', maanden:29}
            ],[
                {naam: 'KIM', maanden:44}, 
                {naam: 'Hubert "Hubo" Sasker', maanden:56}, 
                {naam: 'Spook', maanden:12}, 
                {naam: 'Schaap', maanden:6}, 
                {naam: 'Rutger "Ronald" Bonsel', maanden:61}, 
                {naam: 'Ralph "Frits Berend B." Moolenaar', maanden:12}, 
                {naam: 'Rutger "Ronald" Bonsel', maanden:7}, 
                {naam: 'Razvan', maanden:3}, 
                {naam: 'Rutger "Ronald" Bonsel', maanden:10}, 
                {naam: 'Tim', maanden:6}, 
                {naam: 'Robert "Lucifer"Mendel', maanden:47}, 
                {naam: 'Taja', maanden:4}, 
                {naam: 'Robert "Lucifer"Mendel', maanden:7}, 
                {naam: 'Balázs "Epke" Némethi', maanden:12}, 
                {naam: 'Joost "Kees" Tegelberg', maanden:37}, 
                {naam: 'Steen', maanden:10}, 
                {naam: 'Joost "Kees" Tegelberg', maanden:13}, 
                {naam: 'Thijs "Mort" van den Brink', maanden:50}, 
                {naam: 'Felix "Heinz" Groot', maanden:27}
            ]
        ]

        let jaren = [
            {jaar:"1989", maanden:4},
            {jaar:"1990", maanden:12},
            {jaar:"1991", maanden:12},
            {jaar:"1992", maanden:12},
            {jaar:"1993", maanden:12},
            {jaar:"1994", maanden:12},
            {jaar:"1995", maanden:12},
            {jaar:"1996", maanden:12},
            {jaar:"1997", maanden:12},
            {jaar:"1998", maanden:12},
            {jaar:"1999", maanden:12},
            {jaar:"2000", maanden:12},
            {jaar:"2001", maanden:12},
            {jaar:"2002", maanden:12},
            {jaar:"2003", maanden:12},
            {jaar:"2004", maanden:12},
            {jaar:"2005", maanden:12},
            {jaar:"2006", maanden:12},
            {jaar:"2007", maanden:12},
            {jaar:"2008", maanden:12},
            {jaar:"2009", maanden:12},
            {jaar:"2010", maanden:12},
            {jaar:"2011", maanden:12},
            {jaar:"2012", maanden:12},
            {jaar:"2013", maanden:12},
            {jaar:"2014", maanden:12},
            {jaar:"2015", maanden:12},
            {jaar:"2016", maanden:12},
            {jaar:"2017", maanden:12},
            {jaar:"2018", maanden:12},
            {jaar:"2019", maanden:12},
            {jaar:"2020", maanden:12},
            {jaar:"2021", maanden:12},
            {jaar:"2022", maanden:12},
            {jaar:"2023", maanden:12},
            {jaar:"2024", maanden:12},
        ]

        if(this.state.omgekeerd){
            jaren.reverse()
        }

        return(
            <Card style={{width:'100%', margin: 'auto', maxWidth:'800px'}} title='Tijdlijn'>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{width:'10%'}}>
                        <ToggleButton onLabel="" offLabel="" offIcon={PrimeIcons.SORT_AMOUNT_DOWN} onIcon={PrimeIcons.SORT_AMOUNT_UP_ALT} checked={this.state.omgekeerd} onChange={(e) => this.setState({omgekeerd: e.value})} />
                    </div>
                    <div style={{width:'80%'}}>
                        <Slider min={2000} max={20000} value={this.state.height} onChange={(e) => this.setState({height: e.value})} />
                    </div>
                </div>
                <br />
                <div style={{width:'100%', overflowY:'scroll'}}>
                    {tijden.map((kolom:any, kolomindex:number) => {
                        if(this.state.omgekeerd){
                            kolom.reverse(  )
                        }
                        return(
                            <div style={{display:'flex', marginBottom:10, width:this.state.height}}>
                                {kolom.map((item:any, index:number) => {
                                    let styles:any = {
                                        width: (item.maanden/totaalmaanden)*this.state.height, 
                                        borderRadius:'10px', 
                                        textAlign:'center', 
                                        display:'flex', 
                                        alignItems:'center', 
                                        justifyContent:'center',
                                        marginRight:50/(kolom.length - 1),
                                        height:'40px',
                                        overflow:'hidden'
                                    }
                                    if(this.isEven(index+kolomindex)){
                                        styles.backgroundColor = 'red'
                                        styles.color = 'black'
                                    }else{
                                        styles.backgroundColor = 'blue'
                                        styles.color = 'white' 
                                    }
                                    return(
                                        <div 
                                        data-pr-tooltip={item.naam}
                                        data-pr-position="left"
                                        data-pr-my="left center-2"
                                        className={'huisgenoot' + index.toString()} style={styles}>
                                            <Tooltip target={'.huisgenoot' + index.toString()} />
                                            {item.naam}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    <div style={{display:'flex', marginBottom:10, width:this.state.height}}>
                        {jaren.map((jaar:any, index:number) => {
                            let styles:any = {
                                width: (jaar.maanden/totaalmaanden)*this.state.height, 
                                border:'1px solid black', 
                                textAlign:'center', 
                                display:'flex', 
                                borderRadius:'5px',
                                alignItems:'center', 
                                justifyContent:'center',
                                marginRight:100/(jaren.length - 1),
                                height:'20px',
                                overflow:'hidden'
                            }
                            return(
                                <div style={styles}>
                                    {jaar.jaar}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Card>
        )
    }
}